import request, { requestPublic } from "@/utils/request";

export function qualityTelegramSituation() {
  return request({
    url: `/website/qualityTelegramSituation`,
    method: "POST",
  });
}

export function qualityQuotaSituation(data, config) {
  return request({
    url: `/website/qualityQuotaSituation`,
    method: "POST",
    data,
    ...config,
  });
}
export function firstCateQualifiedRate(data, config) {
  return request({
    url: `/website/firstCateQualifiedRate`,
    method: "POST",
    data,
    ...config,
  });
}

export function banner(data) {
  return requestPublic({
    url: `/dynamic/content/list.do`,
    method: "POST",
    data,
  });
}

export function dataApply(data) {
  return requestPublic({
    url: `/website/dataApply/save.do`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data,
  });
}

export function reportDetails(id) {
  return requestPublic({
    url: `/dynamic/content/get.do?id=${id}`,
  });
}
