export const phone = /^1[35678]\d{9}$/;

export const email =
  /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;

export const customerOffice = /^[\u4e00-\u9fa5()（）]+$/;

export const name = /^[\u4e00-\u9fa5a-zA-Z]+$/;

export const validatePhone = (rule, value, callback, source, options) => {
  if (!value) {
    callback("请输入手机号码");
    return false;
  }
  if (!phone.test(value)) {
    callback("请输入正确的手机号");
    return false;
  }
  return true;
};

export const validateEmail = (rule, value, callback, source, options) => {
  if (!value) {
    callback("请输入邮箱");
    return false;
  }
  if (!email.test(value)) {
    callback("请输入正确的邮箱格式");
    return false;
  }
  return true;
};
export const validateCustomerOffice = (
  rule,
  value,
  callback,
  source,
  options
) => {
  if (!value) {
    callback("请输入单位");
    return false;
  }
  if (!customerOffice.test(value)) {
    callback("请输入单位的中文名称");
    return false;
  }
  return true;
};

export const validateName = (rule, value, callback, source, options) => {
  if (!value) {
    callback("请输入姓名");
    return false;
  }
  if (!name.test(value)) {
    callback("请输入正确的姓名");
    return false;
  }
  return true;
};
export const validateCustomerJob = (rule, value, callback, source, options) => {
  if (!value) {
    callback("请输入职务");
    return false;
  }
  if (!name.test(value)) {
    callback("请输入正确的职务");
    return false;
  }
  return true;
};
