import { defineStore } from "pinia";
import { dataApply } from "@/apis/home";
import { ElMessage } from "element-plus";

var qs = require("qs");

export const useStore = defineStore("platform", {
  state: () => {
    return {
      status: "0", // 0 === 未提交  1 === 已提交
      form: {
        customerName: "",
        customerPhone: "",
        customerEmail: "",
        customerOffice: "",
        customerJob: "",
      },
    };
  },
  actions: {
    async getDataApply(data) {
      var res = await dataApply(qs.stringify(this.form));
      if (res.code === 500) {
        ElMessage({
          message: res.msg,
          type: "error",
        });
      } else {
        this.status = "1";
      }
    },
  },
});
