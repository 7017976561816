import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6dee51d5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "platform"
};
const _hoisted_2 = {
  class: "content-box content"
};
import Search from "./components/Search.vue";
import Success from "./components/Success";
import { useStore } from "./store";
export default {
  __name: 'index',

  setup(__props) {
    const store = useStore();
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(store).status === '0' ? (_openBlock(), _createBlock(Search, {
        key: 0
      })) : (_openBlock(), _createBlock(_unref(Success), {
        key: 1
      }))])), [[_directive_loading, _unref(store).$loading.getDataApply]])]);
    };
  }

};